import React from 'react';
import styled from 'styled-components';

import { Divider, IconButton } from '@material-ui/core';
import { NotificationsRounded } from '@material-ui/icons';
import UserMenu from './components/UserMenu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AlabRojo from '../../../assets/AcropolisLabRojo.png';

const Navegation = props => {
    const { imagenCompany, nameCompany } = props;
    const isAnaldex = nameCompany.includes('Analdex'); //solo éste Incompany requiere la no inclusión del logo de ADEN

    // RETURN
    return (
        <NavegationContainer>
            <Wrapper>
                {/* <IconButton onClick={handleToggle}>
					<MenuRounded color="secondary" />
				</IconButton> */}

                {isAnaldex ? (
                    <Link to="/">
                        <FakeLogo src={`data:image/png;base64,${imagenCompany}`} alt="logo" />
                    </Link>
                ) : (
                    <>
                        <Link to="/">
                            <FakeLogo src={AlabRojo} alt="logo" />
                        </Link>
                        <FakeDivider orientation="vertical" flexItem />
                        <FakeLogo src={`data:image/png;base64,${imagenCompany}`} alt="logo" />
                    </>
                )}
            </Wrapper>
            <Wrapper>
                <IconButton>
                    <NotificationsRounded />
                </IconButton>
                <UserMenu />
            </Wrapper>
        </NavegationContainer>
    );
};

const mapStateToProps = state => ({
    imagenCompany: state.user.user.imagen,
    nameCompany: state.user.user.display_name,
});

export default connect(mapStateToProps)(Navegation);

const NavegationContainer = styled.header`
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    position: fixed;
    top: 0;
    left: 70px;
    right: 0;
    padding: 0 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 900;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const FakeLogo = styled.img`
    width: auto;
    height: 35px;
	background-size: cover;
	background-position: center center;
`;

const FakeDivider = styled(Divider)`
    height: 35px;
    margin: auto;
`;
