import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../shared/layout/header/Header';
import Layout from '../shared/layout/Layout';
import HeaderButtons from '../components/user/HeaderButtons';
import UserData from '../components/user/UserData';
import UserGroups from '../components/user/UserGroups';
import UserContent from '../components/user/UserContent';
import { participantActions } from '../redux/actions';
import CurrentUserSkt from '../components/skeletons/CurrentUserSkt';
import { getPublicProfile } from '../redux/api/participant.api';
import { useState } from 'react';
import UserPublicProfile from '../components/user/UserPublicProfile';

const User = () => {
    let { id } = useParams();

    // REDUX
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const { currentParticipant, loading, error } = useSelector(state => state.participant);
    const [public_profile, setPublicProfile] = useState(null);

    // EFFECTS
    useEffect(() => {
        dispatch(participantActions.getParticipantRequest({ ondemand_id: user.id, user_id: id }));
    }, []);

    useEffect(() => {
        !!currentParticipant &&
            getPublicProfile(currentParticipant?.crm_partner_id).then(data => {
                setPublicProfile(data.response_data);
            });
    }, [currentParticipant]);

    if (error !== null) {
        <Redirect to="/" />;
    }
    return (
        <>
            {loading ? (
                <CurrentUserSkt />
            ) : (
                currentParticipant !== null && (
                    <Layout
                        header={
                            <Header route={`${currentParticipant.nombre ? currentParticipant.nombre : ''}`}>
                                <HeaderButtons tab="2" id={parseInt(id)} group={false} rol="user" currentParticipant={currentParticipant} />
                            </Header>
                        }>
                        <Container>
                            <UserData currentParticipant={currentParticipant} />
                            {public_profile && <UserPublicProfile public_profile={public_profile} />}
                            <UserGroups groups={currentParticipant.grupos} />
                            <UserContent contents={currentParticipant.objetos_aprendizaje} type="course" />
                            <UserContent contents={currentParticipant.asignaturas} type="subject" />
                        </Container>
                    </Layout>
                )
            )}
        </>
    );
};

export default User;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
